iframe#agreement-frame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  border: none;
}

iframe#iframe-tutorial {
  width: 100%;
  height: 600px;
}

/* *********** visual style starts *********** */
:root {
  font-size: 14px;
  --primaryColor: #1f1f46;
  --primaryDarkColor: #16172a;
  --secondaryColor: #e0be74;
  --secondaryDarkColor: #c56d19;
  --secondaryLightColor: #a2916b;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
a.color-link {
  color: var(--primaryColor);
}
a.color-link:hover {
  text-decoration: underline;
}

.hide {
  display: none;
}

html {
  background: #fff;
  font-family: "Microsoft YaHei", Tahoma, "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  overflow-x: hidden;
  width: 100%;
}

body {
  margin: 0;
  width: 100%;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
  background-color: #fff;
  color: #707070;
}

body.ready .body-wrapper {
  opacity: 1;
}

input:focus {
  outline: 0;
}

.container {
  margin: auto;
  max-width: 1200px;
  width: 100%;
}

.svg-mask.resize .image {
  transform: scale(0.71);
}
.svg-mask .image {
  background-color: var(--primaryColor);
  mask-repeat: no-repeat;
  mask-position: center;
  margin: auto;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-color {
  background-color: #008bf0;
  border: 0;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  padding: 5px 15px;
  text-align: center;
  width: 120px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-color.hide {
  display: none;
}
.btn-color:focus {
  outline: 0;
}
.btn-color:hover {
  background-color: var(--secondaryColor);
}

/* *********** header *********** */
header .top-login {
  background-color: var(--primaryDarkColor);
  color: #fff;
  min-height: 35px;
  display: flex;
  align-items: center;
  padding: 5px 0;
}
header .top-login .login-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .top-login .login-wrapper .date-time-wrapper {
  font-size: 12px;
}
header .top-login .login-wrapper .date-time-wrapper .date-time {
  margin-right: 5px;
}
header .top-login .login-wrapper .login-box {
  display: inline-flex;
}
header .top-login .login-wrapper .login-box.hide {
  display: none;
}
header .top-login .login-wrapper .login-box input {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  padding: 6px 10px 6px 25px;
  margin-left: 5px;
  width: 120px;
}
header .top-login .login-wrapper .login-box input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  text-align: right;
}
header .top-login .login-wrapper .login-box .username {
  background: url(../img/input_username.svg) 4px center no-repeat;
}
header .top-login .login-wrapper .login-box .password {
  background: url(../img/input_pwd.svg) 4px center no-repeat;
}
header .top-login .login-wrapper .login-box .vcode {
  position: relative;
}
header .top-login .login-wrapper .login-box .vcode input {
  padding: 6px 10px;
}
header .top-login .login-wrapper .login-box .vcode input::placeholder {
  text-align: left;
}
header .top-login .login-wrapper .login-box .vcode .verify-code {
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 2px;
  width: 54px;
  height: 88%;
}
header .top-login .login-wrapper .login-box .forgot-pwd {
  border: 0;
  border-radius: 20px;
  padding: 2px 6px;
  color: #4f94e8;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
header .top-login .login-wrapper .login-box .forgot-pwd:hover {
  color: var(--secondaryColor);
}
header .top-login .login-wrapper .login-box .btn-color {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  width: 78px;
}
header .top-login .login-wrapper .login-box .btn-color.red {
  background-color: #b92328;
}
header .top-login .login-wrapper .login-box .btn-color.blue {
  background-color: #4589e5;
  width: 50px;
}
header .top-login .login-wrapper .login-box .btn-color.purple {
  background-color: #c549e7;
}
header .top-login .login-wrapper .login-box .btn-color:hover {
  background-color: var(--secondaryColor);
}
header .top-login .login-wrapper .header-links {
  display: flex;
}
header .top-login .login-wrapper .header-links.hide {
  display: none;
}
header .top-login .login-wrapper .header-links ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0;
}
header .top-login .login-wrapper .header-links ul li {
  list-style: none;
}
header .top-login .login-wrapper .header-links ul li:not(:last-child) {
  margin-right: 20px;
}
header .top-login .login-wrapper .header-links ul li a {
  color: var(--secondaryColor);
  white-space: nowrap;
}
header .top-login .login-wrapper .header-links ul li a:hover {
  color: #fff;
}
header .top-login .login-wrapper .header-links ul li span {
  color: #fff;
}
header .top-nav {
  background-color: var(--primaryColor);
  color: var(--secondaryColor);
}
header .top-nav .menu {
  display: flex;
  align-items: center;
  position: relative;
}
header .top-nav .menu .logo {
  display: flex;
  justify-content: center;
  flex: 1;
}
header .top-nav .menu .logo img {
  height: 54px;
  padding: 5px;
}
header .top-nav .menu .nav {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: auto;
}
header .top-nav .menu .nav .menu-button {
  height: 68px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
header .top-nav .menu .nav .menu-button:before {
  background-color: var(--secondaryColor);
  content: "";
  height: 3px;
  width: 100%;
  position: absolute;
  top: 0;
  transform: scaleX(0);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
header .top-nav .menu .nav .menu-button.active, header .top-nav .menu .nav .menu-button:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), transparent);
}
header .top-nav .menu .nav .menu-button.active:before, header .top-nav .menu .nav .menu-button:hover:before {
  transform: scaleX(1);
}
header .top-nav .menu .nav .menu-button svg {
  margin-bottom: 6px;
}
header .top-nav .menu .nav .menu-button.lottery:hover + .lottery-dropdown {
  opacity: 1;
  display: block;
}
header .lottery-dropdown {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  position: absolute;
  left: 0;
  top: 68px;
  opacity: 0;
  display: none;
  width: 100%;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 5;
}
header .lottery-dropdown a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
header .lottery-dropdown a.hide {
  display: none;
}
header .lottery-dropdown a .lottery-box {
  flex-basis: 14.2857142857%;
  text-align: center;
}
header .lottery-dropdown a .lottery-box .image {
  height: 100px;
  width: 100px;
}
header .lottery-dropdown a .lottery-box .image:hover {
  background-color: #008bf0;
}
header .lottery-dropdown:hover {
  opacity: 1;
  display: block;
}

/* *********** footer *********** */
footer {
  background-color: var(--primaryColor);
  color: rgba(255, 255, 255, 0.6);
}
footer .info {
  display: flex;
  padding-top: 40px;
}
footer .info .service-col {
  color: var(--secondaryColor);
  flex-basis: 50%;
  font-size: 1.2rem;
}
footer .info .service-col .service-wrapper {
  width: 60%;
}
footer .info .service-col .service-wrapper .title {
  font-weight: bold;
}
footer .info .service-col .service-wrapper .time-wrapper {
  display: flex;
  align-items: center;
}
footer .info .service-col .service-wrapper .time-wrapper .time {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 1.6rem;
}
footer .info .service-col .cs-wrapper {
  display: flex;
  align-items: center;
}
footer .info .service-col .cs-wrapper svg {
  margin-right: 10px;
}
footer .info .service-col .cs-wrapper .phone-num {
  font-size: 1.6rem;
  letter-spacing: 1px;
}
footer .info .brand-col {
  flex-basis: 50%;
}
footer .info .brand-col .logo img {
  height: 68px;
}
footer .info .brand-col .title {
  font-size: 1.2rem;
  font-weight: bold;
}
footer .info .brand-col .brand-ability div {
  margin-bottom: 20px;
}
footer .progress-bar {
  width: 100%;
  height: 6px;
  background: rgba(217, 217, 217, 0.5);
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  margin-top: 8px;
  margin-bottom: 26px;
}
footer .progress-bar.progress1 span, footer .progress-bar.progress2 span {
  background-color: var(--secondaryColor);
  position: absolute;
  left: 0;
  top: 0;
  height: 6px;
  border-radius: 20px;
  transform: scaleX(0);
  transform-origin: left;
}
footer .progress-bar.progress1 span {
  animation: expand 3s ease-out forwards;
  width: 37%;
}
footer .progress-bar.progress2 span {
  animation: expand 3s ease-out forwards;
  width: 60%;
}
@keyframes expand {
  to {
    transform: scaleX(1);
  }
}
footer .payment {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  padding: 30px 0;
}
footer .payment .title {
  font-size: 1.2rem;
}
footer .payment .col {
  flex-basis: 50%;
}
footer .payment .payment-box-wrapper {
  display: flex;
}
footer .payment .payment-box-wrapper .payment-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-basis: 12.5%;
}
footer .payment .payment-box-wrapper .payment-box img {
  margin-bottom: 12px;
}
footer .payment .payment-box-wrapper .payment-box.title {
  font-size: 1.2rem;
  justify-content: initial;
}
footer .payment .techsup-wrapper .sgwin-wrapper {
  display: flex;
  align-items: center;
}
footer .payment .techsup-wrapper .sgwin-wrapper img {
  margin-right: 20px;
  margin-top: 5px;
}
footer .disclaimer {
  background-color: var(--primaryDarkColor);
}
footer .disclaimer .disclaimer-wrapper {
  display: flex;
  line-height: 18px;
  padding: 30px 0;
}
footer .disclaimer .disclaimer-wrapper .left {
  flex-basis: 20%;
}
footer .disclaimer .disclaimer-wrapper .left div:first-child {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 6px;
}
footer .disclaimer .disclaimer-wrapper .right {
  flex-basis: 80%;
}

/* ***** sidebar ****** */
.left-sidebar {
  color: #fff;
  text-align: center;
  position: fixed;
  z-index: 4;
  left: 5px;
  top: 25%;
  width: 160px;
}
.left-sidebar .left-navigator img {
  position: relative;
  z-index: 4;
}
.left-sidebar .left-qr-wrapper {
  background-image: linear-gradient(to bottom, var(--secondaryColor), var(--secondaryDarkColor));
  border-radius: 8px;
  height: 180px;
  width: 100px;
  margin: -46px auto 0 auto;
  position: relative;
}
.left-sidebar .left-qr-wrapper .appqr-wrapper {
  padding-top: 42px;
}
.left-sidebar .left-qr-wrapper .qr-code {
  background-color: #fff;
  height: 90px;
  width: 90px;
  margin: auto;
}
.left-sidebar .vertical {
  background: #000;
  border-radius: 0 0 5px 5px;
  transform: rotate(90deg);
  transform-origin: top left;
  position: absolute;
  left: 0;
  top: 50px;
  padding: 2px 10px;
}

.right-sidebar {
  position: fixed;
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;
}
.right-sidebar > a {
  position: relative;
  margin-bottom: 5px;
}
.right-sidebar .right-item {
  background-image: linear-gradient(to bottom, var(--secondaryColor), var(--secondaryDarkColor));
  border-radius: 8px;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.right-sidebar .right-item:hover .img-side .image {
  background-color: var(--primaryColor);
}
.right-sidebar .right-item .img-side .image {
  background-color: #fff;
  width: 48px;
  height: 48px;
}

.right-item:hover .sidebar-desc {
  transform: translate(0, -50%);
  opacity: 1;
  visibility: visible;
}
.right-item .sidebar-desc {
  min-width: 40px;
  max-width: 140px;
  display: block;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  text-decoration: none;
  position: absolute;
  top: 50%;
  transform: translate(-30%, -50%);
  right: 60px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 6px;
  padding: 5px;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 0.8125rem;
  color: #fff;
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.right-item .sidebar-desc:before {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid rgba(0, 0, 0, 0.7);
}
.right-item .sidebar-desc img,
.right-item .sidebar-desc .qr-code {
  display: block;
  max-width: 130px;
}
.right-item .sidebar-desc div {
  word-break: break-all;
}

/* *********** index *********** */
.banner {
  height: 495px;
  position: relative;
}
.banner .swiper-container {
  height: 100%;
}
.banner .swiper-container .swiper-wrapper {
  height: 100%;
}
.banner .swiper-container .swiper-wrapper .swiper-slide {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  overflow: hidden;
  position: relative;
}
.banner .swiper-container .swiper-wrapper .swiper-slide > img {
  height: 100%;
  z-index: 1;
}
.banner .swiper-container .swiper-wrapper .swiper-slide > img.img-bg {
  position: absolute;
  width: 130%;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  filter: blur(10px);
  z-index: -1;
}
.banner .swiper-container .swiper-wrapper .swiper-slide > img.img-words {
  position: absolute;
  height: 35%;
  left: 26%;
  transform: translateX(-1000px);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.banner .swiper-container .swiper-wrapper .swiper-slide > img.img-words.animate {
  transform: translateX(0);
}

.notice {
  background-color: var(--secondaryColor);
  color: #000;
  height: 40px;
  line-height: 40px;
}
.notice .marquee-wrapper {
  display: flex;
  overflow: hidden;
}
.notice .marquee-wrapper .notice-box {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  white-space: nowrap;
  width: 400px;
}
.notice .marquee-wrapper .notice-box img {
  margin-left: 5px;
}
.notice .marquee-wrapper .marquee-box {
  position: relative;
  box-sizing: border-box;
  line-height: 40px;
  height: 40px;
  padding: 0 8px;
  width: 92%;
}
.notice .marquee-wrapper .marquee-box ul {
  overflow: hidden;
  zoom: 1;
  margin: 0;
}
.notice .marquee-wrapper .marquee-box ul li {
  margin-right: 20px;
  float: left;
  height: 40px;
  line-height: 40px;
  text-align: left;
  display: inline;
  width: auto !important;
}

.hotgame {
  background: url("../img/hotgame_bg.png") center no-repeat #fff;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.hotgame .leafbg {
  position: absolute;
  top: 0;
  opacity: 1;
  z-index: -1;
}
.hotgame .leafbg.left {
  left: 5%;
  transform: translate(0, 0px) rotateZ(45deg);
  animation: fallingLeaf 5s linear infinite;
}
.hotgame .leafbg.right {
  right: 5%;
  transform: translate(0, 0px) rotateZ(0deg);
  animation: fallingLeaf 7s linear infinite;
  animation-delay: 500ms;
}
.hotgame .leafbg.right img {
  width: 150px;
}
@keyframes fallingLeaf {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    transform: translate(50px, 300px) rotateZ(-45deg);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(100px, 600px) rotateZ(0deg);
  }
}
.hotgame .hotgame-wrapper {
  display: flex;
  justify-content: center;
}
.hotgame .hotgame-wrapper .col-left {
  width: 40%;
}
.hotgame .hotgame-wrapper .col-left .hotgame-desc-box {
  line-height: 1.6rem;
  text-align: center;
}
.hotgame .hotgame-wrapper .col-left .hotgame-desc-box:not(:last-child) {
  margin-bottom: 70px;
}
.hotgame .hotgame-wrapper .col-left .hotgame-desc-box .title {
  color: #b10000;
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.4rem;
  margin-bottom: 12px;
  margin-top: 4px;
}
.hotgame .hotgame-wrapper .col-left .hotgame-desc-box .btn-color {
  margin-top: 20px;
}
.hotgame .hotgame-wrapper .col-right {
  color: #b10000;
  text-align: center;
  width: 60%;
}
.hotgame .hotgame-wrapper .col-right .title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 5px;
}
.hotgame .hotgame-wrapper .col-right .game-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.hotgame .hotgame-wrapper .col-right .game-wrapper .game-box {
  flex-basis: 33.3333333333%;
}
.hotgame .hotgame-wrapper .col-right .game-wrapper .game-box .image {
  height: 120px;
  width: 120px;
}
.hotgame .hotgame-wrapper .col-right .game-wrapper .game-box .image:hover {
  background-color: #008bf0;
}
.hotgame .hotgame-wrapper .col-right .game {
  margin-top: 20px;
}
.hotgame .hotgame-wrapper .col-right .game .swiper-pagination {
  position: unset;
}
.hotgame .hotgame-wrapper .col-right .game .swiper-pagination-bullet-active {
  background: var(--primaryColor);
}

/* *********** faqs *********** */
.faq {
  background: url("../img/faq_bg.png") center top no-repeat #ededed;
  padding: 30px;
  box-sizing: border-box;
  min-height: calc(100vh - 113px - 574px);
}

.faq-wrapper {
  display: flex;
}
.faq-wrapper .faq-menu {
  background-color: #dcdcdc;
  border-right: 1px solid #d6d6d6;
  flex-basis: 17%;
  font-size: 1.2rem;
}
.faq-wrapper .faq-menu .newbie {
  background-color: #b10000;
  color: #fff;
  padding: 18px;
  font-weight: bold;
  text-align: center;
}
.faq-wrapper .faq-menu .faq-menu-button {
  cursor: pointer;
  padding: 18px 40px;
  box-sizing: border-box;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.faq-wrapper .faq-menu .faq-menu-button.active, .faq-wrapper .faq-menu .faq-menu-button:hover {
  background-color: #fff;
}
.faq-wrapper .faq-content-col {
  background: url("../img/faq_panda.png") top right no-repeat #fff;
  background-position-y: 80px;
  flex-basis: 83%;
  padding: 20px 32px;
}
.faq-wrapper .faq-content-col a {
  color: var(--primaryColor);
}
.faq-wrapper .faq-content-col h2 {
  border-bottom: 1px solid #656565;
  padding-bottom: 5px;
  color: #b10000;
}
.faq-wrapper .faq-content-col h4 {
  margin-bottom: 8px;
}
.faq-wrapper .faq-content-col p {
  margin-top: 0;
  margin-bottom: 5px;
}

/* *********** app *********** */
.app {
  background: url("../img/app_bg.png") center no-repeat/cover #fff;
  min-height: calc(100vh - 113px - 574px);
  padding-top: 36px;
}
.app .app-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.app .app-wrapper girl-col {
  flex-basis: 50%;
}
.app .app-wrapper .qr-col {
  flex-basis: 50%;
  text-align: center;
}
.app .app-wrapper .qr-col .qr-box {
  background: url("../img/app_panda.png") center no-repeat;
  height: 270px;
  width: 200px;
  margin: auto;
  position: relative;
}
.app .app-wrapper .qr-col .qr-box .qr-code-wrapper {
  padding-top: 98px;
  padding-left: 10px;
}
.app .app-wrapper .qr-col .qr-box .qr-code {
  background-color: #fff;
  height: 130px;
  width: 130px;
  margin: auto;
}
.app .app-wrapper .qr-col .qr-box .download {
  background-color: #b10000;
  border-radius: 5px 5px 0 0;
  color: #fff;
  padding: 2px 10px;
  position: absolute;
  right: -111px;
  top: 90px;
  transform-origin: top left;
  transform: rotate(90deg);
}
.app .app-wrapper .qr-col .title {
  color: var(--primaryColor);
  font-size: 2rem;
  margin-bottom: 10px;
}
.app .app-wrapper .qr-col .btn-color {
  margin-bottom: 20px;
  margin-right: 5px;
  width: 80px;
}
.app .app-wrapper .qr-col .online-games {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

/* *********** affindex *********** */
.aff {
  background-color: #000;
  min-height: calc(100vh - 113px - 574px);
  color: #fff;
}
.aff .aff-wrapper {
  display: flex;
}
.aff .aff-wrapper .phone-col {
  position: relative;
}
.aff .aff-wrapper .phone-col .img-phone {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: phone 4s cubic-bezier(0.4, 0, 0.2, 1) infinite alternate;
}
@keyframes phone {
  0% {
    transform: translate(-50%, -60%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
.aff .aff-wrapper .text-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.1rem;
}
.aff .aff-wrapper .text-col .title {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 25px;
}
.aff .aff-wrapper .text-col .subtitle {
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 0;
}
.aff .aff-wrapper .text-col div {
  margin-bottom: 15px;
}

.aff-content {
  padding: 10px 15vw;
}
.aff-content .title {
  margin-top: 30px;
  font-size: 2rem;
  font-weight: bold;
  color: #1c9cf0;
}
.aff-content .btn-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}
.aff-content .btn-wrapper .btn-color {
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 30px;
  padding: 10px 15px;
  text-align: center;
  width: 120px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.aff-content .btn-wrapper .btn-color.hide {
  display: none;
}
.aff-content .btn-wrapper .btn-color:focus {
  outline: 0;
}
.aff-content .btn-wrapper .btn-color:hover {
  background-color: #105d90;
  box-shadow: 3px 2px #ccd7f1;
}

/* *********** register *********** */
.register {
  background: url("../img/app_bg.png") center no-repeat/cover #fff;
  padding: 30px;
  min-height: calc(100vh - 113px - 574px);
  box-sizing: border-box;
}

.register-container {
  background-color: #fff;
}
.register-container .reg-steps {
  width: 75%;
  margin: auto;
}
.register-container .reg-steps .reg-steps-box {
  background-color: #ececec;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  display: inline-block;
  float: left;
  line-height: 32pt;
  text-align: center;
  vertical-align: middle;
  width: 33.3333333333%;
}
.register-container .reg-steps .reg-steps-box.active {
  background: var(--primaryColor);
  color: #fff;
}
.register-container .reg-steps .reg-steps-box .steps-no {
  display: inline-block;
  vertical-align: middle;
  font-size: 18pt;
  color: #978e8e;
}
.register-container .reg-steps .reg-steps-box .steps-title {
  display: inline-block;
  vertical-align: middle;
  font-size: 10.5pt;
  color: #978e8e;
}
.register-container .reg-steps .reg-steps-box .steps-no.active,
.register-container .reg-steps .reg-steps-box .steps-title.active {
  color: #fff;
}
.register-container .register-form {
  padding: 75px 0 30px 0;
  box-sizing: border-box;
}
.register-container .register-form .row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.register-container .register-form .row.button {
  justify-content: center;
  margin-top: 20px;
}
.register-container .register-form .row .text {
  flex-basis: 20%;
  text-align: right;
  margin-right: 20px;
}
.register-container .register-form .row .inp {
  flex-basis: 30%;
  margin-right: 10px;
}
.register-container .register-form .row .inp input {
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
  padding: 2px 10px;
  height: 32px;
  width: 100%;
  -webkit-appearance: none;
  background: transparent;
  outline: none;
}
.register-container .register-form .row .tip {
  flex-basis: 50%;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

/* ***** promo ****** */
.promo {
  background: url("../img/app_bg.png") center no-repeat/cover fixed #fff;
  padding: 30px;
  min-height: calc(100vh - 113px - 574px);
  box-sizing: border-box;
}
.promo .promo-wrapper {
  background-color: #fff;
  color: #333;
  padding: 10px;
}
.promo .promo-wrapper .icon {
  cursor: pointer;
}
.promo .promo-wrapper .icon img {
  width: 100%;
}
.promo .promo-wrapper a {
  color: var(--primaryColor);
}
.promo .promo-wrapper .sub-content-item-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 50px;
  margin-bottom: 20px;
}

.cooperation-row {
  margin-top: 50px;
}
.cooperation-row .cooperation-list {
  display: flex;
}
.cooperation-row .cooperation-list > div {
  text-align: left;
}
.cooperation-row .cooperation-list .cooperation-title {
  flex-basis: 150px;
  font-weight: bold;
}
.cooperation-row .cooperation-list .cooperation-title:after {
  content: ":";
}
.cooperation-row .cooperation-list .cooperation-qrcode {
  width: 150px;
  background: white;
}