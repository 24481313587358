@import "./../../common/styles";

$red: #b10000;
$blue: #008bf0;
$full-page-height: calc(100vh - 113px - 574px);
$transition-all: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
$shadow: #ccd7f1;
$buttonColor: #1c9cf0;
$hoverButtonColor: #105d90;

/* *********** visual style starts *********** */
:root {
  font-size: 14px;
  --primaryColor: #1f1f46;
  --primaryDarkColor: #16172a;
  --secondaryColor: #e0be74;
  --secondaryDarkColor: #c56d19;
  --secondaryLightColor: #a2916b;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: $transition-all;

  &.color-link {
    color: var(--primaryColor);

    &:hover {
      text-decoration: underline;
    }
  }
}

.hide {
  display: none;
}

html {
  background: #fff;
  font-family: "Microsoft YaHei", Tahoma, "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  margin: 0;
  overflow-x: hidden;
  width: 100%;
}

body {
  margin: 0;
  width: 100%;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
  background-color: #fff;
  color: #707070;
}
body.ready .body-wrapper {
  opacity: 1;
}

input:focus {
  outline: 0;
}

.container {
  margin: auto;
  max-width: 1200px;
  width: 100%;
}

.svg-mask {
  &.resize {
    .image {
      transform: scale(0.71);
    }
  }
  .image {
    background-color: var(--primaryColor);
    mask-repeat: no-repeat;
    mask-position: center;
    margin: auto;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.btn-color {
  background-color: $blue;
  border: 0;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  padding: 5px 15px;
  text-align: center;
  width: 120px;
  transition: $transition-all;
  &.hide {
    display: none;
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: var(--secondaryColor);
  }
}

/* *********** header *********** */
header {
  .top-login {
    background-color: var(--primaryDarkColor);
    color: #fff;
    min-height: 35px;
    display: flex;
    align-items: center;
    padding: 5px 0;

    .login-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .date-time-wrapper {
        font-size: 12px;

        .date-time {
          margin-right: 5px;
        }
      }

      .login-box {
        display: inline-flex;

        &.hide {
          display: none;
        }
        input {
          background-color: transparent;
          border: 1px solid rgba(255, 255, 255, 0.3);
          border-radius: 4px;
          color: #fff;
          font-size: 1rem;
          padding: 6px 10px 6px 25px;
          margin-left: 5px;
          width: 120px;
          &::placeholder {
            color: rgba(255, 255, 255, 0.5);
            text-align: right;
          }
        }
        .username {
          background: url(../img/input_username.svg) 4px center no-repeat;
        }
        .password {
          background: url(../img/input_pwd.svg) 4px center no-repeat;
        }
        .vcode {
          position: relative;
          input {
            padding: 6px 10px;
            &::placeholder {
              text-align: left;
            }
          }
          .verify-code {
            border-radius: 4px;
            position: absolute;
            right: 0;
            top: 2px;
            width: 54px;
            height: 88%;
          }
        }
        .forgot-pwd {
          border: 0;
          border-radius: 20px;
          padding: 2px 6px;
          color: #4f94e8;
          display: flex;
          align-items: center;
          white-space: nowrap;
          &:hover {
            color: var(--secondaryColor);
          }
        }
        .btn-color {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-left: 5px;
          width: 78px;
          &.red {
            background-color: #b92328;
          }
          &.blue {
            background-color: #4589e5;
            width: 50px;
          }
          &.purple {
            background-color: #c549e7;
          }
          &:hover {
            background-color: var(--secondaryColor);
          }
        }
      }

      .header-links {
        display: flex;

        &.hide {
          display: none;
        }
        ul {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 3px 0;
          li {
            list-style: none;
            &:not(:last-child) {
              margin-right: 20px;
            }
            a {
              color: var(--secondaryColor);
              white-space: nowrap;
              &:hover {
                color: #fff;
              }
            }
            span {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .top-nav {
    background-color: var(--primaryColor);
    color: var(--secondaryColor);
    .menu {
      display: flex;
      align-items: center;
      position: relative;
      .logo {
        display: flex;
        justify-content: center;
        flex: 1;
        img {
          height: 54px;
          padding: 5px;
        }
      }
      .nav {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-left: auto;
        .menu-button {
          height: 68px;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          transition: $transition-all;
          &:before {
            background-color: var(--secondaryColor);
            content: "";
            height: 3px;
            width: 100%;
            position: absolute;
            top: 0;
            transform: scaleX(0);
            transition: $transition-all;
          }
          &.active,
          &:hover {
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), transparent);
            &:before {
              transform: scaleX(1);
            }
          }
          svg {
            margin-bottom: 6px;
          }
          &.lottery:hover + .lottery-dropdown {
            opacity: 1;
            display: block;
          }
        }
      }
    }
  }

  .lottery-dropdown {
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    position: absolute;
    left: 0;
    top: 68px;
    opacity: 0;
    display: none;
    width: 100%;
    transition: $transition-all;
    z-index: 5;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      &.hide {
        display: none;
      }
      .lottery-box {
        flex-basis: calc(100% / 7);
        text-align: center;
        .image {
          height: 100px;
          width: 100px;
          &:hover {
            background-color: $blue;
          }
        }
      }
    }
    &:hover {
      opacity: 1;
      display: block;
    }
  }
}

/* *********** footer *********** */
footer {
  background-color: var(--primaryColor);
  color: rgba(255, 255, 255, 0.6);
  .info {
    display: flex;
    padding-top: 40px;
    .service-col {
      color: var(--secondaryColor);
      flex-basis: 50%;
      font-size: 1.2rem;
      .service-wrapper {
        width: 60%;
        .title {
          font-weight: bold;
        }
        .time-wrapper {
          display: flex;
          align-items: center;
          .time {
            display: flex;
            align-items: center;
            margin-left: auto;
            font-size: 1.6rem;
          }
        }
      }
      .cs-wrapper {
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }
        .phone-num {
          font-size: 1.6rem;
          letter-spacing: 1px;
        }
      }
    }
    .brand-col {
      flex-basis: 50%;
      .logo {
        img {
          height: 68px;
        }
      }
      .title {
        font-size: 1.2rem;
        font-weight: bold;
      }
      .brand-ability {
        div {
          margin-bottom: 20px;
        }
      }
    }
  }

  .progress-bar {
    width: 100%;
    height: 6px;
    background: rgba(217, 217, 217, 0.5);
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
    margin-top: 8px;
    margin-bottom: 26px;
    &.progress1 span,
    &.progress2 span {
      background-color: var(--secondaryColor);
      position: absolute;
      left: 0;
      top: 0;
      height: 6px;
      border-radius: 20px;
      transform: scaleX(0);
      transform-origin: left;
    }
    &.progress1 span {
      animation: expand 3s ease-out forwards;
      width: 37%;
    }
    &.progress2 span {
      animation: expand 3s ease-out forwards;
      width: 60%;
    }
    @keyframes expand {
      to {
        transform: scaleX(1);
      }
    }
  }

  .payment {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    padding: 30px 0;
    .title {
      font-size: 1.2rem;
    }
    .col {
      flex-basis: 50%;
    }
    .payment-box-wrapper {
      display: flex;
      .payment-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-basis: calc(100% / 8);
        img {
          margin-bottom: 12px;
        }
        &.title {
          font-size: 1.2rem;
          justify-content: initial;
        }
      }
    }
    .techsup-wrapper {
      .sgwin-wrapper {
        display: flex;
        align-items: center;
        img {
          margin-right: 20px;
          margin-top: 5px;
        }
      }
    }
  }
  .disclaimer {
    background-color: var(--primaryDarkColor);
    .disclaimer-wrapper {
      display: flex;
      line-height: 18px;
      padding: 30px 0;
      .left {
        flex-basis: 20%;
        div:first-child {
          font-size: 1.6rem;
          font-weight: bold;
          margin-bottom: 6px;
        }
      }
      .right {
        flex-basis: 80%;
      }
    }
  }
}

/* ***** sidebar ****** */
.left-sidebar {
  color: #fff;
  text-align: center;
  position: fixed;
  z-index: 4;
  left: 5px;
  top: 25%;
  width: 160px;
  .left-navigator {
    img {
      position: relative;
      z-index: 4;
    }
  }
  .left-qr-wrapper {
    background-image: linear-gradient(to bottom, var(--secondaryColor), var(--secondaryDarkColor));
    border-radius: 8px;
    height: 180px;
    width: 100px;
    margin: -46px auto 0 auto;
    position: relative;
    .appqr-wrapper {
      padding-top: 42px;
    }
    .qr-code {
      background-color: #fff;
      height: 90px;
      width: 90px;
      margin: auto;
    }
  }
  .vertical {
    background: #000;
    border-radius: 0 0 5px 5px;
    transform: rotate(90deg);
    transform-origin: top left;
    position: absolute;
    left: 0;
    top: 50px;
    padding: 2px 10px;
  }
}

.right-sidebar {
  position: fixed;
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;

  > a {
    position: relative;
    margin-bottom: 5px;
  }

  .right-item {
    background-image: linear-gradient(to bottom, var(--secondaryColor), var(--secondaryDarkColor));
    border-radius: 8px;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition-all;
    &:hover .img-side .image {
      background-color: var(--primaryColor);
    }
    .img-side .image {
      background-color: #fff;
      width: 48px;
      height: 48px;
    }
  }
}

.right-item {
  &:hover .sidebar-desc {
    transform: translate(0, -50%);
    opacity: 1;
    visibility: visible;
  }

  .sidebar-desc {
    min-width: 40px;
    max-width: 140px;
    display: block;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    text-decoration: none;
    position: absolute;
    top: 50%;
    transform: translate(-30%, -50%);
    right: 60px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 6px;
    padding: 5px;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 0.8125rem;
    color: #fff;
    //white-space: nowrap;
    transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: -10px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid rgba(0, 0, 0, 0.7);
    }

    img,
    .qr-code {
      display: block;
      max-width: 130px;
    }
    div {
      word-break: break-all;
    }
  }
}

/* *********** index *********** */
.banner {
  height: 495px;
  position: relative;
  .swiper-container {
    height: 100%;
    .swiper-wrapper {
      height: 100%;
      .swiper-slide {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
        overflow: hidden;
        position: relative;
        > img {
          height: 100%;
          z-index: 1;
          &.img-bg {
            position: absolute;
            width: 130%;
            height: auto;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            filter: blur(10px);
            z-index: -1;
          }
          &.img-words {
            position: absolute;
            height: 35%;
            left: 26%;
            transform: translateX(-1000px);
            transition: $transition-all;
            &.animate {
              transform: translateX(0);
            }
          }
        }
      }
    }
  }
}

.notice {
  background-color: var(--secondaryColor);
  color: #000;
  height: 40px;
  line-height: 40px;
  .marquee-wrapper {
    display: flex;
    overflow: hidden;
    .notice-box {
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      white-space: nowrap;
      width: 400px;
      img {
        margin-left: 5px;
      }
    }
    .marquee-box {
      position: relative;
      box-sizing: border-box;
      line-height: 40px;
      height: 40px;
      padding: 0 8px;
      width: 92%;
      ul {
        overflow: hidden;
        zoom: 1;
        margin: 0;
        li {
          margin-right: 20px;
          float: left;
          height: 40px;
          line-height: 40px;
          text-align: left;
          display: inline;
          width: auto !important;
        }
      }
    }
  }
}

.hotgame {
  background: url("../img/hotgame_bg.png") center no-repeat #fff;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  z-index: 0;
  .leafbg {
    position: absolute;
    top: 0;
    opacity: 1;
    z-index: -1;
    &.left {
      left: 5%;
      transform: translate(0, 0px) rotateZ(45deg);
      animation: fallingLeaf 5s linear infinite;
    }
    &.right {
      right: 5%;
      transform: translate(0, 0px) rotateZ(0deg);
      animation: fallingLeaf 7s linear infinite;
      animation-delay: 500ms;
      img {
        width: 150px;
      }
    }
  }
  @keyframes fallingLeaf {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    50% {
      transform: translate(50px, 300px) rotateZ(-45deg);
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(100px, 600px) rotateZ(0deg);
    }
  }

  .hotgame-wrapper {
    display: flex;
    justify-content: center;
    .col-left {
      width: 40%;
      .hotgame-desc-box {
        line-height: 1.6rem;
        text-align: center;
        &:not(:last-child) {
          margin-bottom: 70px;
        }
        .title {
          color: $red;
          font-size: 2rem;
          font-weight: bold;
          line-height: 2.4rem;
          margin-bottom: 12px;
          margin-top: 4px;
        }
        .btn-color {
          margin-top: 20px;
        }
      }
    }
    .col-right {
      color: $red;
      text-align: center;
      width: 60%;
      .title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .game-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .game-box {
          flex-basis: calc(100% / 3);
          .image {
            height: 120px;
            width: 120px;
            &:hover {
              background-color: $blue;
            }
          }
        }
      }
      .game {
        margin-top: 20px;
        .swiper-pagination {
          position: unset;
        }
        .swiper-pagination-bullet-active {
          background: var(--primaryColor);
        }
      }
    }
  }
}

/* *********** faqs *********** */
.faq {
  background: url("../img/faq_bg.png") center top no-repeat #ededed;
  padding: 30px;
  box-sizing: border-box;
  min-height: $full-page-height;
}

.faq-wrapper {
  display: flex;
  .faq-menu {
    background-color: #dcdcdc;
    border-right: 1px solid #d6d6d6;
    flex-basis: 17%;
    font-size: 1.2rem;
    .newbie {
      background-color: $red;
      color: #fff;
      padding: 18px;
      font-weight: bold;
      text-align: center;
    }
    .faq-menu-button {
      cursor: pointer;
      padding: 18px 40px;
      box-sizing: border-box;
      transition: $transition-all;
      &.active,
      &:hover {
        background-color: #fff;
      }
    }
  }

  .faq-content-col {
    background: url("../img/faq_panda.png") top right no-repeat #fff;
    background-position-y: 80px;
    flex-basis: 83%;
    padding: 20px 32px;
    a {
      color: var(--primaryColor);
    }
    h2 {
      border-bottom: 1px solid #656565;
      padding-bottom: 5px;
      color: $red;
    }
    h4 {
      margin-bottom: 8px;
    }
    p {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
}

/* *********** app *********** */
.app {
  background: url("../img/app_bg.png") center no-repeat / cover #fff;
  min-height: $full-page-height;
  padding-top: 36px;

  .app-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    girl-col {
      flex-basis: 50%;
    }
    .qr-col {
      flex-basis: 50%;
      text-align: center;
      .qr-box {
        background: url("../img/app_panda.png") center no-repeat;
        height: 270px;
        width: 200px;
        margin: auto;
        position: relative;
        .qr-code-wrapper {
          padding-top: 98px;
          padding-left: 10px;
        }
        .qr-code {
          background-color: #fff;
          height: 130px;
          width: 130px;
          margin: auto;
        }
        .download {
          background-color: $red;
          border-radius: 5px 5px 0 0;
          color: #fff;
          padding: 2px 10px;
          position: absolute;
          right: -111px;
          top: 90px;
          transform-origin: top left;
          transform: rotate(90deg);
        }
      }
      .title {
        color: var(--primaryColor);
        font-size: 2rem;
        margin-bottom: 10px;
      }
      .btn-color {
        margin-bottom: 20px;
        margin-right: 5px;
        width: 80px;
      }
      .online-games {
        font-size: 1.4rem;
        margin-bottom: 10px;
      }
    }
  }
}

/* *********** affindex *********** */
.aff {
  background-color: #000;
  min-height: $full-page-height;
  color: #fff;
  .aff-wrapper {
    display: flex;
    .phone-col {
      position: relative;
      .img-phone {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: phone 4s cubic-bezier(0.4, 0, 0.2, 1) infinite alternate;
      }

      @keyframes phone {
        0% {
          transform: translate(-50%, -60%);
        }
        100% {
          transform: translate(-50%, -50%);
        }
      }
    }
    .text-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 1.1rem;
      .title {
        font-size: 3rem;
        text-align: center;
        margin-bottom: 25px;
      }
      .subtitle {
        font-size: 1.6rem;
        text-align: center;
        margin-bottom: 0;
      }
      div {
        margin-bottom: 15px;
      }
    }
  }
}

.aff-content {
  padding: 10px 15vw;
  .title {
    margin-top: 30px;
    font-size: 2rem;
    font-weight: bold;
    color: $buttonColor;
  }

  .btn-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;

    .btn-color {
      border-radius: 5px;
      color: #fff;
      display: inline-block;
      font-size: 1rem;
      font-weight: bold;
      margin-right: 30px;
      padding: 10px 15px;
      text-align: center;
      width: 120px;
      transition: $transition-all;
      &.hide {
        display: none;
      }
      &:focus {
        outline: 0;
      }
      &:hover {
        background-color: $hoverButtonColor;
        box-shadow: 3px 2px $shadow;
      }
    }
  }
}

/* *********** register *********** */
.register {
  background: url("../img/app_bg.png") center no-repeat / cover #fff;
  padding: 30px;
  min-height: $full-page-height;
  box-sizing: border-box;
}

.register-container {
  background-color: #fff;
  .reg-steps {
    width: 75%;
    margin: auto;

    .reg-steps-box {
      background-color: #ececec;
      border: 1px solid #c2c2c2;
      box-sizing: border-box;
      display: inline-block;
      float: left;
      line-height: 32pt;
      text-align: center;
      vertical-align: middle;
      width: calc(100% / 3);
      &.active {
        background: var(--primaryColor);
        color: #fff;
      }
      .steps-no {
        display: inline-block;
        vertical-align: middle;
        font-size: 18pt;
        color: #978e8e;
      }
      .steps-title {
        display: inline-block;
        vertical-align: middle;
        font-size: 10.5pt;
        color: #978e8e;
      }
      .steps-no.active,
      .steps-title.active {
        color: #fff;
      }
    }
  }

  .register-form {
    padding: 75px 0 30px 0;
    box-sizing: border-box;

    .row {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      &.button {
        justify-content: center;
        margin-top: 20px;
      }

      .text {
        flex-basis: 20%;
        text-align: right;
        margin-right: 20px;
      }
      .inp {
        flex-basis: 30%;
        margin-right: 10px;
        input {
          border: 1px solid #c0c0c0;
          border-radius: 4px;
          box-sizing: border-box;
          font-size: 1rem;
          padding: 2px 10px;
          height: 32px;
          width: 100%;
          -webkit-appearance: none;
          background: transparent;
          outline: none;
        }
      }
      .tip {
        flex-basis: 50%;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
      }
    }
  }
}

/* ***** promo ****** */
.promo {
  background: url("../img/app_bg.png") center no-repeat / cover fixed #fff;
  padding: 30px;
  min-height: $full-page-height;
  box-sizing: border-box;
  .promo-wrapper {
    background-color: #fff;
    color: #333;
    padding: 10px;
    .icon {
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    a {
      color: var(--primaryColor);
    }
    .sub-content-item-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      height: 50px;
      margin-bottom: 20px;
    }
  }
}

.cooperation-row {
  margin-top: 50px;

  .cooperation-list {
    display: flex;

    > div {
      text-align: left;
    }

    .cooperation-title {
      flex-basis: 150px;
      font-weight: bold;

      &:after {
        content: ":";
      }
    }

    .cooperation-qrcode {
      width: 150px;
      background: white;
    }
  }
}
